@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@300&family=Tenor+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Port+Lligat+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC&display=swap");

.scrollshadow {
  box-shadow: 0 -14rem 101rem rgb(34, 31, 177), 0 -5rem 5rem rgb(34, 31, 177);
}
.scrollshadowwhite {
  box-shadow: 0 -14rem 101rem rgb(255, 255, 255),
    0 -5rem 5rem rgb(255, 255, 255);
}

/* all font */
.antonfont {
  font-family: "Anton", sans-serif !important;
}
.playpenfont {
  font-family: "Playpen Sans", cursive !important;
}
.tenorfont {
  font-family: "Tenor Sans", sans-serif !important;
}
.juliusfont {
  font-family: "Julius Sans One", sans-serif !important;
}
.bubblegumfont {
  font-family: "Bubblegum Sans", sans-serif !important;
}
.portlligatfont {
  font-family: "Port Lligat Sans", sans-serif !important;
}
.encodefont {
  font-family: "Encode Sans SC", sans-serif !important;
}

.kanitfont {
  font-family: "Kanit", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}
/* other */
.sourcecodepro {
  font-family: "Source Code Pro", monospace;
}

.servicescardshadow {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
.servicescardshadowwrapper {
  transition: all 0.4s;
}
.servicescardshadowwrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 1rem;
}
